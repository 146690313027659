const mapping: Record<string, Record<string, string>> = {
  'fr-ca': {
    'https://www.globalpayments.com/en-us/privacy-statement': 'https://www.globalpayments.com/fr-ca/confidentialite',
    'https://www.globalpayments.com/en-us/terms-of-use': 'https://www.globalpayments.com/fr-ca/conditions-utilisation'
  },
  'de-de': {
    'https://www.evopayments.eu/en/gpa-privacy-policy': 'https://www.evopayments.eu/de/gpa-datenschutz',
    'https://www.evopayments.eu/en/gpa-terms-of-use': 'https://www.evopayments.eu/de/gpa-agb',
    'https://www.evopayments.eu/en/gpa-gdpr': 'https://www.evopayments.eu/de/gpa-dsgvo',
    'https://www.commerz-globalpay.com/en-de/privacy-policy': 'https://www.commerz-globalpay.com/de-de/datenschutz',
    'https://www.commerz-globalpay.com/en-en/tcs': 'https://www.commerz-globalpay.com/de-de/agb',
    'https://www.commerz-globalpay.com/en-de/gdpr': 'https://www.commerz-globalpay.com/de-de/dsgvo'
  }
};

export const getLocatedFooterLink = (locale: string, url: string): string => {
  const lowerCaseLocale = locale.toLowerCase();
  return (lowerCaseLocale in mapping && mapping[lowerCaseLocale][url]) || url;
};
